// src/Signup.js
import React, { useEffect } from 'react';

function Signup() {
  useEffect(() => {
    initiateOAuth2Flow();
  }, []);

  async function initiateOAuth2Flow() {
    const codeVerifier = generateCodeVerifier();
    const codeChallenge = await generateCodeChallenge(codeVerifier);
    const state = generateRandomString(16);

    // Use environment variables
    const backendUrl = process.env.REACT_APP_OAUTH_URL;
    console.log('Backend URL:', backendUrl);
    const redirectUri = process.env.REACT_APP_REDIRECT_URI;
    const clientId = process.env.REACT_APP_CLIENT_ID;

    // Store codeVerifier and state in sessionStorage
    sessionStorage.setItem('pkce_code_verifier', codeVerifier);
    sessionStorage.setItem('oauth_state', state);

    const nextUrl = '/dashboard'; // The URL to redirect after successful authentication
    const stateWithNext = JSON.stringify({ state, nextUrl });

    const params = new URLSearchParams({
      response_type: 'code',
      client_id: clientId, // Replace with your client ID
      redirect_uri: redirectUri,
      code_challenge: codeChallenge,
      code_challenge_method: 'S256',
      state: stateWithNext, // Embed nextUrl within state
    });

    window.location.href = `${backendUrl}/authorize?${params.toString()}`;
  }

  function generateCodeVerifier() {
    const length = 128;
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    let codeVerifier = '';
    for (let i = 0; i < length; i++) {
      codeVerifier += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return codeVerifier;
  }

  async function generateCodeChallenge(codeVerifier) {
    const digest = await sha256(codeVerifier);
    return base64UrlEncode(digest);
  }

  async function sha256(plain) {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    const hash = await window.crypto.subtle.digest('SHA-256', data);
    return hash;
  }

  function base64UrlEncode(arrayBuffer) {
    const uint8Array = new Uint8Array(arrayBuffer);
    let base64 = window.btoa(String.fromCharCode(...uint8Array));
    base64 = base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
    return base64;
  }

  function generateRandomString(length) {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const array = new Uint8Array(length);
    window.crypto.getRandomValues(array);
    for (let i = 0; i < length; i++) {
      result += charset[array[i] % charset.length];
    }
    return result;
  }

  return (
    <div>
      <p>Redirecting to login...</p>
    </div>
  );
}

export default Signup;
