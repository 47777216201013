// src/Callback.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Callback() {
  const navigate = useNavigate();

  useEffect(() => {
    async function handleCallback() {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const state = urlParams.get('state');

        // Use environment variables
        const backendUrl = process.env.REACT_APP_OAUTH_URL;
        const redirectUri = process.env.REACT_APP_REDIRECT_URI;
        const clientId = process.env.REACT_APP_CLIENT_ID;


        console.log('Received code:', code);
        console.log('Received state:', state);

        const storedState = sessionStorage.getItem('oauth_state');
        const codeVerifier = sessionStorage.getItem('pkce_code_verifier');

        console.log('Stored state:', storedState);
        console.log('Code verifier:', codeVerifier);

        if (state !== storedState) {
          console.error('Invalid state');
          return;
        }

        if (!code) {
          console.error('No authorization code found');
          return;
        }

        // Exchange the authorization code for tokens
        const response = await fetch(`${backendUrl}/token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            grant_type: 'authorization_code',
            code: code,
            redirect_uri: redirectUri,
            client_id: clientId, // Use your client ID
            code_verifier: codeVerifier,
          }),
          credentials: 'include',
        });

        console.log('Token response status:', response.status);
        const data = await response.json();
        console.log('Token response data:', data);

        if (response.ok) {
          // Store tokens securely
          localStorage.setItem('access_token', data.access_token);
          localStorage.setItem('refresh_token', data.refresh_token);

          // Clear PKCE data
          sessionStorage.removeItem('oauth_state');
          sessionStorage.removeItem('pkce_code_verifier');

          // Redirect to the dashboard
          console.log('Redirecting to /dashboard');
          navigate('/dashboard');
        } else {
          console.error('Token exchange failed:', data);
        }
      } catch (error) {
        console.error('Error in handleCallback:', error);
      }
    }

    handleCallback();
  }, [navigate]);

  return (
    <div>
      <p>Processing authentication...</p>
    </div>
  );
}

export default Callback;
